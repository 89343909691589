﻿@import "_variables.scss";

@media (min-width: 992px) {
    // colored strip in the background
    #menu-left {
        display: table-cell;
        vertical-align: top;
        width: 190px;
        background-color: $gray-light;
    }
    #menu-right {
        display: table-cell;
        vertical-align: top;
        width: 180px;
        background-color: $gray-lighter;
    }

    #content-center {
        display: table-cell;
        vertical-align: top;

    }

}

@media (max-width: 991px) {
    #menu-left-content {
        top: 45px;
        li.blank4 {
            display: none;
        }
    }

}

#menu-left-content {
    z-index: 999;
    width: 190px;
    min-width: 150px;
    background-color: transparent;
    overflow: hidden;
    ul {
        border-width: 0;
        background-color: transparent;
        background-image: none;
        li.k-item {
            height: 3em;
            a {
                color: white;
                height: 3em;
                line-height: 3em;
                background-color: $brand-primary-light;
                border-color: $brand-primary;
                background-image: none;

                &:hover {
                    background-color: $brand-primary-lighter;
                    span.glyphicon {
                        color: $brand-warning;
                    }
                }

                span.glyphicon {
                    font-size: 1.3em;
                    top: 0.15em;
                    margin-right: 1em;
                }
            }
            a.k-state-selected { // selected!
                background-color: $brand-primary-lighter;
                box-shadow: none;
                span.glyphicon {
                    color: $brand-primary;
                }
            }
        }
        li.blank1 {
            height: 3em;
            background-color: transparent;
        }
        li.blank4 {
            height: 13em;
            background-color: transparent;
            border-bottom: 1px solid $brand-warning;
            span.k-link {
                background-color: transparent;
                border: none;
                padding: 0;
            }
        }

        li.k-state-highlight::after {
            content: "";
            background-color: $brand-warning;
            width: 5px;
            height: 3em;
            display: block;
            position: relative;
            top: -3.1em;
        }

    }



    .k-item .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
    @-webkit-keyframes pulse {
        0% { background-color: $brand-primary-light; }
        100% { background-color: $brand-warning; }
    }
    @-moz-keyframes pulse {
        0% { background-color: $brand-primary-light; }
        100% { background-color: $brand-warning; }
    }

    @keyframes pulse {
        0% { background-color: $brand-primary-light; }
        100% { background-color: $brand-warning; }
    }
    .k-item .pulse {
        -webkit-animation-name: pulse;
        animation-name: pulse;
    }

    .languages {
        margin-top: 3em;
        margin-left: 1.5em;

        img {
            width: 24px;
            cursor: pointer;
        }
    }

}

#menu-right-content {
    width: 140px; 
    min-width: 140px;
    margin: 20px;
    .k-content {
        background-color: inherit;
    }
}