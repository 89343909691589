﻿

.clickable {
    cursor: pointer;
} 

.strike {
    color: red;
    .points {
        text-decoration: line-through;
    }
}
.posOutput {
    font-size: smaller; 
    position: relative; 
    top: 5px;
}

dl.spacing dd {
    margin-bottom: 2em;
}

.strong {
    font-weight: bold;
}

.larger {
    font-size: larger !important;
}

.width100 {
    width: 100px;
}

.smaller {
    font-size: smaller !important;
}

.personalBest {
    font-weight: bold;
    background-color: lime;
    color: black;
}

.totalBest {
    font-weight: bold;
    background-color: fuchsia;
    color: black;
}

.invalidLap {
    font-weight: bold;
    background-color: red;
    color: black;

}

.nomargin {
    margin: 0;
}

th.rotate {
  /* Something you can count on */
  height: 180px;
  white-space: nowrap;
  font-weight: normal;
  font-size: smaller;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */
    translate(8px, 150px)
    /* 45 is really 360 - 45 */
    rotate(270deg);
  width: 30px;
}
th.rotate > div > span {
  /*border-bottom: 1px solid #ccc;*/
  /*padding: 5px 10px;*/
}

