﻿@import "_variables.scss";

div[kendo-tab-strip].k-tabstrip-left {
    > ul.k-tabstrip-items {
        width: 178px;
    }
    > div {
        margin-left: 180px !important;
    }
}

.navbar {
    border-radius: 0;
    padding-right: 20px;
}

div.k-upload {
    .k-clear-selected {
        background-color: $brand-danger;
        color: white;
        font-weight: bold;
    }

    .k-upload-selected {
        background-color: $brand-success;
        color: white;
        font-weight: bold;
    }
}