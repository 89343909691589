﻿@import "_variables.scss";

#css2js {
    display: none;
}

/* APP LAYOUT*/
/* ==========*/
html {
    height: 100%;

    body {
        min-height: 100%;

        .brand-background {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-size: cover;
            z-index: -1;
            //background-image: url("/Content/images/BRANDING/BACKGROUND.JPG");
        }

        #mainframe {
            overflow: hidden;
            background-color: white;

            .container-fluid {
                padding: 0;
            }

            #body-content {
                position: relative;

                .navbar {
                    margin-bottom: 0;
                }

                .navbar-header {
                    margin-left: 0;
                    width: 100%;
                }
            }


            .brand-banner {
                height: 300px;
                min-width: 200px;
                text-align: center;
                background-position-y: 0;
                background-repeat: no-repeat;
                margin-bottom: 10px;
                //background-image: url("/Content/images/BRANDING/BANNER-Ferrari.JPG");
                position: relative;

                .brand-logo {
                    //background-image: url("/Content/images/logo.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 200px;
                    width: 200px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    margin-right: 20px;
                }

                .alert {
                    padding: 5px;
                    margin-bottom: 0;
                }

            }

            #layout-content {
                    margin-bottom: 2em;
                    .folderAction, .fileAction {
                        display: block;
                        position: fixed;
                        z-index: 100;
                        background-color: white;
                        margin: 1em;
                        padding: 1em;
                        border: 1px solid $brand-primary;
                        width: 60%;
                        max-width: 400px;
                        min-width: 300px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        //border-radius: 10px;
                        box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
                        h3 {
                            color: $brand-warning;
                            margin-top: 0;
                        }
                        a {
                            text-align: left;
                        }
                        .glyphicon {
                            margin-right: 10px;
                            font-size: 1.5em;
                            top: 0.2em;
                        }

                    }

                    .background {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: black;
                        opacity: 0.5;
                        z-index: 99;
                    }


                h3 {
                    margin-top: 0;
                }
                .row {
                    overflow: visible;
                }
            }

            .breadcrumb {
                font-size: 12px;
            }
            .nav-buttons .btn {
                margin-bottom: 10px;
                @include button-variant($brand-primary, $gray-lighter, transparent);
            }
        }
    }
}

/*Encancements for Large Layouts = background-image in frame*/
@media (min-width: 992px) {
    html {
        body {
            background-size: cover;

            #mainframe { 
                width: 100%;
                //max-width: 1170px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 0px;
                margin-bottom: 0px;
                //border: 1 solid $brand-primary;
                border-radius: 0px;
                //box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);

                #layout-content {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    html {
        body {
            .brand-background {
                display: none;
            }

            #mainframe {
                .brand-banner {
                    height: 14em;
                    background-size: cover;
                    background-position: center center;
                }

                #layout-content {
                    padding-left: 10px;
                    padding-right: 10px;

                }
            }
        }
    }
}


